
import { Component, Vue } from "vue-property-decorator";

import importRoutes from "@/api/routes/imports";
import CFileDownload from "@/components/FileDownload.vue";
import Preloader from "@/components/Preloader.vue";

interface IImport {
  id: string;
  file_name: string;
  file_url: string;
  created_at: string;
}

@Component({
  components: {
    CFileDownload,
    Preloader
  }
})
export default class VImport extends Vue {
  $refs!: {
    file: HTMLFormElement;
  };

  protected readonly importTypes = [
    {
      value: "structures",
      label: "Орг. структура",
      type_id: 1
    },
    {
      value: "positions",
      label: "Должности",
      type_id: 2
    },
    {
      value: "staffs",
      label: "Пользователи",
      type_id: 3
    }
  ];

  protected currentImportType = { ...this.importTypes[0] };

  protected acceptFiles = ["application/json"];
  protected auth_token: string | null = null;

  protected uploadProgress: number | null = null;
  protected uploadErrors: string | null = null;
  protected uploadUrl: string | null = null;

  protected currentTab: number = 0;

  protected imports: IImport[] = [];
  protected current_page: number = 1;
  protected per_page: number = 100;
  protected next_page: boolean = false;
  protected preload: boolean = false;
  protected infinite_preload: boolean = false;

  protected showForm: string | null = localStorage.getItem("showImportForm");

  protected async created() {
    this.preload = true;

    await this.loadImports(this.current_page);

    this.preload = false;
  }

  protected async uploadFile() {
    if (!this.auth_token?.length) {
      this.uploadErrors = "Auth Token must exist";

      return;
    }

    const file: File = this.$refs.file.files[0];

    if (!this.acceptFiles.includes(file?.type)) {
      this.uploadErrors = "acceptFiles not valid";

      return;
    }

    switch (this.currentImportType.value) {
      case "structures":
        this.uploadUrl = importRoutes.post_structures;
        break;
      case "positions":
        this.uploadUrl = importRoutes.post_positions;
        break;
      case "staffs":
        this.uploadUrl = importRoutes.post_staffs;
        break;
    }

    if (!this.uploadUrl) {
      this.uploadErrors = "importUrl not valid";

      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    this.$api
      .import(this.uploadUrl, formData, {
        headers: {
          "Conent-Type": "multipart/form-data",
          Authorization: this.auth_token
        },
        onUploadProgress: progressEvent => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        }
      })
      .then(() => {
        this.uploadProgress = 100;
        this.uploadErrors = null;
      })
      .catch(({ response: res }) => {
        this.uploadErrors = res.data.error;
      });
  }

  protected async loadImports(page: number) {
    this.infinite_preload = true;

    this.$api
      .get(importRoutes.get_imports, {
        params: {
          type_id: this.importTypes[this.currentTab].type_id,
          page,
          per_page: this.per_page
        }
      })
      .then(({ data: res }: { data: IImport[] }) => {
        this.imports.push(...res);

        this.current_page = page;
        this.next_page = res.length === this.per_page;
      })
      .finally(() => {
        this.infinite_preload = false;
      });
  }

  protected async infiniteHandler() {
    if (this.next_page && !this.infinite_preload) {
      await this.loadImports(this.current_page + 1);
    }
  }

  protected async changeTab(tab_index: number) {
    if (this.currentTab !== tab_index) {
      this.currentTab = tab_index;

      this.preload = true;
      this.imports = [];

      await this.loadImports(1);

      this.preload = false;
    }
  }
}
